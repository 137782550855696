var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "loader-container d-flex align-center justify-center" },
    [
      _c("v-fade-transition", [
        _vm.show
          ? _c(
              "div",
              { staticClass: "loader" },
              [
                _c("v-progress-circular", {
                  attrs: { indeterminate: "", size: "120" }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }