import ApiModel from "../APIModel";

export default class GlobalApps extends ApiModel {
  constructor() {
    super("global_apps");
  }

  async get(payload = {}) {
    return await this.http
      .ignoreErrorHandler()
      .get(`/${this.resourceUrl}`, payload);
  }
}
