import store from "@/store";

const getRoutes = () => {
  const meta = {
    module: "users",
    showSearch: true
  };

  const credentials = store.getters["authentication/credentials"];

  if (!credentials.user) {
    return [];
  }

  const {
    user: { position }
  } = credentials;

  const routes: { [value: string]: object } = {
    admin: [
      {
        path: `/${meta.module}`,
        redirect: `/${meta.module}/page/1`
      },
      {
        path: `/${meta.module}/page`,
        redirect: `/${meta.module}/page/1`
      },
      {
        path: `/${meta.module}/page/:page`,
        name: `${meta.module}`,
        component: () =>
          import(
            /* webpackChunkName: "users" */ `@/modules/${meta.module}/views/index.vue`
          ),
        meta: {
          ...meta
        }
      }
    ]
  };

  return routes[position];
};

export default getRoutes();
