export const TABLE_ACTION_HEADER = {
  text: "Actions",
  value: "_actions",
  width: "136px",
  align: "center",
  sortable: false
};

export const DATE_TIME_FORMATS = {
  LONG: "MM/DD/YYYY HH:mm"
};
