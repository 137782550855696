var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [_vm.showGlobalLoader ? _c("page-loader") : _vm._e()],
        1
      ),
      _c("app-layout")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }