// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./Roboto-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./BEER.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./War is Over.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./RobotoCondensed-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./Retro Team.otf");
exports = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"Roboto\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n  font-family: \"BEER\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n  font-family: \"WarIsOver\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n@font-face {\n  font-family: \"RobotoCondensedBold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n@font-face {\n  font-family: \"RetroTeam\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n}", "",{"version":3,"sources":["stylesheet.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,4CAA8B;AAChC;AACA;EACE,mBAAmB;EACnB,4CAAoB;AACtB;AACA;EACE,wBAAwB;EACxB,4CAA2B;AAC7B;AACA;EACE,kCAAkC;EAClC,4CAAoC;AACtC;AACA;EACE,wBAAwB;EACxB,4CAA0B;AAC5B","file":"stylesheet.css","sourcesContent":["@font-face {\n  font-family: \"Roboto\";\n  src: url(\"Roboto-Regular.ttf\");\n}\n@font-face {\n  font-family: \"BEER\";\n  src: url(\"BEER.ttf\");\n}\n@font-face {\n  font-family: \"WarIsOver\";\n  src: url(\"War is Over.ttf\");\n}\n@font-face {\n  font-family: \"RobotoCondensedBold\";\n  src: url(\"RobotoCondensed-Bold.ttf\");\n}\n@font-face {\n  font-family: \"RetroTeam\";\n  src: url(\"Retro Team.otf\");\n}"]}]);
// Exports
module.exports = exports;
