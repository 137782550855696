import ApiModel from "../APIModel";

export default class User extends ApiModel {
  constructor() {
    super("users");
  }

  async getS3Settings(userId: string, params: object) {
    return await this.http
      .ignoreErrorHandler(500)
      .ignoreGlobalPreloader()
      .get(`users/${userId}/s3_settings`, params);
  }

  async createS3Settings(userId: string, payload: object) {
    return await this.http
      .ignoreErrorHandler(500)
      .ignoreGlobalPreloader()
      .post(`users/${userId}/s3_settings`, payload);
  }

  async showS3Settings(userId: string, settingId: string): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/${userId}/s3_settings/${settingId}`, {});
  }

  async editS3Settings(
    userId: string,
    settingId: string,
    payload: object
  ): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .patch(`${this.resourceUrl}/${userId}/s3_settings/${settingId}`, payload);
  }

  async deleteS3Settings(userId: string, settingId: string): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .delete(`${this.resourceUrl}/${userId}/s3_settings/${settingId}`, {});
  }

  async checkStatus(id: string) {
    return await this.http
      .ignoreGlobalPreloader()
      .ignoreErrorHandler(500)
      .get(`users/${id}/read`, {});
  }

  async getDashboard() {
    return await this.http.get(`users/dashboard`, {});
  }

  async addClient(payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .post(`${this.resourceUrl}/add_client`, payload);
  }

  async changePassword(id: number, payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`${this.resourceUrl}/${id}/change_password`, payload);
  }

  async changeTwoStepAuth(payload: object): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .patch(`${this.resourceUrl}/two-step-auth`, payload);
  }
}
