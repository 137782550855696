import Vue from 'vue'
import Vuex from 'vuex'
import localization from './modules/localization'
import alert from './modules/alert'
import preloader from './modules/preloader'
import authentication from './modules/authentication'
import navigation from './modules/navigation'
import user from './modules/user'
import files from './modules/files'
import search from '@/store/modules/search'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    localization,
    alert,
    authentication,
    user,
    navigation,
    files,
    preloader,
    search,
  },
})
