
import Vue from "vue";

export default Vue.extend({
  name: "ConfirmDialog",

  props: {
    executeAction: {
      type: Function,
      required: true
    }
  },

  data: () => ({
    showed: false as boolean,
    params: {
      confirmText: "Confirm",
      cancelText: "Cancel"
    } as any
  }),

  methods: {
    open(params: object): void {
      this.params = { ...this.params, ...params };
      this.showed = true;
    },
    close(): void {
      this.showed = false;
    },
    confirm(): void {
      this.executeAction(this.params);
      this.close();
    },
    cancel(): void {
      this.close();
    }
  }
});
