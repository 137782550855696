import Auth from "@/api/system/Auth";
import Users from "@/api/system/Users";
import Files from "./system/Files";
import Profile from "./system/Profile";
import Roles from "@/api/system/Roles";
import AppsCategories from "@/api/store/AppsCategories";
import GlobalApps from "@/api/store/GlobalApps";
import UserApps from "@/api/store/UserApps";
import RSS from "@/api/store/RSS";
import Weather from "@/api/store/Weather";
import SportScores from "@/api/store/SportScores";
import GroupEx from "@/api/store/GroupEx";

export default {
  auth: () => new Auth(),
  user: () => new Users(),
  roles: () => new Roles(),
  files: () => new Files(),
  profile: () => new Profile(),
  appsCategories: () => new AppsCategories(),
  globalApps: () => new GlobalApps(),
  userApps: () => new UserApps(),
  rss: () => new RSS(),
  weather: () => new Weather(),
  sportScores: () => new SportScores(),
  groupEx: () => new GroupEx()
};
