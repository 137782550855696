import ApiModel from "../APIModel";

export default class UserApps extends ApiModel {
  constructor() {
    super("user_apps");
  }

  async download(url: string): Promise<any> {
    return await this.http.setResponseType("text").get(url, {});
  }

  async getOne(id: string): Promise<any> {
    return await this.http
      .setResponseType("text")
      .get(`${this.resourceUrl}/${id}`, {});
  }
}
