const meta = {
  module: "store",
  showSearch: true
};

export default [
  {
    path: `/`,
    name: "store",
    component: () =>
      import(/* webpackChunkName: "files" */ "@/modules/store/views/index.vue"),
    meta: {
      ...meta
    }
  }
];
