var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { id: "dialog", persistent: "", width: "60vh" },
          model: {
            value: _vm.showed,
            callback: function($$v) {
              _vm.showed = $$v
            },
            expression: "showed"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-img",
                { attrs: { src: _vm.src, contain: "", height: "80vh" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-8",
                      attrs: {
                        absolute: "",
                        color: "primary",
                        fab: "",
                        right: "",
                        top: "",
                        "x-small": ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }