import APIModel from "../APIModel";

export default class Files extends APIModel {
  constructor() {
    super("bucket_files");
  }

  async create(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler()
      .useFormData()
      .post(`${this.resourceUrl}`, payload);
  }

  async update(payload: any): Promise<any> {
    return await this.http
      .ignoreErrorHandler(422)
      .useFormData()
      .post(`${this.resourceUrl}/${payload.id}?_method=PATCH`, payload);
  }

  async downloadFile(id: number): Promise<any> {
    return await this.http
      .setResponseType("blob")
      .get(`${this.resourceUrl}/download/${id}`, {});
  }
}
