import APIModel from "../APIModel";

export default class Auth extends APIModel {
  constructor() {
    super("oauth");
  }

  async login(payload = {}) {
    return await this.http.ignoreErrorHandler().post(`/login`, payload);
  }

  async register(payload = {}) {
    return await this.http
      .ignoreErrorHandler()
      .post(`/${this.resourceUrl}/registration`, payload);
  }

  async loginByTwoStepAuth(payload = {}) {
    return await this.http
      .ignoreErrorHandler()
      .post(`/two-step-login`, payload);
  }

  async checkResetToken(token: string) {
    return await this.http
      
      .get(`/${this.resourceUrl}/check-reset-token/${token}`, {});
  }

  async forgot(payload = {}) {
    return await this.http
      
      .ignoreGlobalPreloader()
      .useFormData()
      .post(`/${this.resourceUrl}/forgot-password`, payload);
  }

  async resetPassword(payload = {}) {
    return await this.http
      
      .ignoreGlobalPreloader()
      .useFormData()
      .post(`/${this.resourceUrl}/reset-password`, payload);
  }
}
