export default {
  namespaced: true,

  state: {
    files: []
  },

  mutations: {
    set(state: any, payload: any) {
      state.files = payload;
    },
    remove(state: any) {
      state.files = [];
    }
  },

  actions: {
    set(context: any, payload: any) {
      context.commit("set", payload);
    },
    remove(context: any) {
      context.commit("remove");
    }
  },

  getters: {
    get: (state: any): boolean => state.files
  }
};
