import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Api from "@/api/API";
import confirm from "@/directives/confirm";
import clipboard from "@/directives/clipboard";
import i18n from "./plugins/i18n";
import { abilitiesPlugin } from "@casl/vue";
import ability from "./plugins/ability";
import VueApexCharts from "vue-apexcharts";
import fullscreen from "@/directives/fullscreen";
import moment from "moment-timezone";
import handleUnsaved from "@/directives/handle-unsaved";
import rules from "@/services/helpers/validation-rules";
// @ts-ignore
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
// @ts-ignore
import preview from "@/modules/preview/views/index";
// @ts-ignore
import publicScreen from "@/modules/preview/views/public-screen";

import "@/assets/fonts/tt-norms-pro/stylesheet.css";
import "@/assets/fonts/stylesheet.css";

// @ts-ignore
import VueGapi from "vue-gapi";

Vue.use(VueGapi, {
  apiKey: "AIzaSyDaQWoMtDVvhn3nCIOF-Xk9VxzBYPaDjEE",
  clientId:
    "355837721373-1fbqo0158b3efqt5brbk4dmljtacf45e.apps.googleusercontent.com",
  discoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
  ],
  scope: "https://www.googleapis.com/auth/calendar.readonly"
});

Vue.config.productionTip = false;

Vue.prototype.$moment = moment;
Vue.prototype.$API = Api;
Vue.prototype.$rules = rules;

Vue.use(VueApexCharts);
Vue.use(abilitiesPlugin, ability);
Vue.use(VueTelInputVuetify, {
  vuetify
});

Vue.component("apexchart", VueApexCharts);

Vue.directive("confirm", confirm);
Vue.directive("clipboard", clipboard);
Vue.directive("fullscreen", fullscreen);
Vue.directive("handle-unsaved", handleUnsaved);

const vue = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");

export default vue;

/*const instance = new Vue({
  store,
  vuetify,
  i18n,
  components: { App },
  data: () => ({ config: {} }),
  render: function(createElement) {
    return createElement("app", {
      props: { config: this.config }
    });
  }
});

const previewApp = new Vue({
  store,
  vuetify,
  i18n,
  components: { preview },
  data: () => ({ config: {} }),
  render: function(createElement) {
    return createElement(preview, {
      props: { config: this.config }
    });
  }
});

const publicScreenApp = new Vue({
  store,
  vuetify,
  i18n,
  components: { publicScreen },
  data: () => ({ config: {} }),
  render: function(createElement) {
    return createElement(publicScreen, {
      props: { config: this.config }
    });
  }
});

export default {
  AppStore: class {
    constructor(element: Element, config: object) {
      Vue.set(instance, "config", {
        ...config
      });
      // @ts-ignore
      instance.$mount(element);
      return instance;
    }
  },
  previewApp: class {
    constructor(element: Element, config: object) {
      Vue.set(previewApp, "config", {
        ...config
      });
      // @ts-ignore
      previewApp.$mount(element);
      return previewApp;
    }
  },
  publicScreen: class {
    constructor(element: Element, config: object) {
      Vue.set(publicScreenApp, "config", {
        ...config
      });
      // @ts-ignore
      publicScreenApp.$mount(element);
      return publicScreenApp;
    }
  }
};*/
