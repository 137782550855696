import store from "@/store";

const credentials = store.getters["authentication/credentials"];

const meta = {
  module: "errors",
  errorPage: true,
  layout: credentials.user ? "default" : "auth",
  withoutCredentials: true
};

export default [
  {
    path: `*`,
    redirect: `/error/404`
  },
  {
    path: `/error/404`,
    name: "error404",
    component: () =>
      import(
        /* webpackChunkName: "error404" */ "@/modules/errors/views/404.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/error/403`,
    name: "error403",
    component: () =>
      import(
        /* webpackChunkName: "error403" */ "@/modules/errors/views/403.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/error/500`,
    name: "error500",
    component: () =>
      import(
        /* webpackChunkName: "error500" */ "@/modules/errors/views/500.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/error/unknown`,
    name: "errorUnknown",
    component: () =>
      import(
        /* webpackChunkName: "error" */ "@/modules/errors/views/unknown.vue"
      ),
    meta: {
      ...meta
    }
  }
];
