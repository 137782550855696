export default {
  admin: [
    {
      action: ["show"],
      subject: ["NavProfile"]
    }
  ],
  client_root: [
    {
      action: ["show"],
      subject: ["NavProfile"]
    }
  ],
  client_sub: [
    {
      action: ["show"],
      subject: ["NavProfile"]
    }
  ]
};
