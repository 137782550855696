
import Vue from "vue";

export default Vue.extend({
  name: "PageLoader",

  data: () => ({
    loaded: [] as Array<any>,
    show: true
  }),

  mounted() {
    setTimeout(() => {
      window.requestAnimationFrame(this.loadGradient);
    }, 300);
  },

  beforeDestroy() {
    this.show = false;
    this.loaded = [1, 1, 1, 1];
  },

  methods: {
    loadGradient() {
      this.loaded.push(1);

      if (this.loaded.length < 4) {
        setTimeout(() => {
          window.requestAnimationFrame(this.loadGradient);
        }, 100);
      }
    }
  }
});
