import ApiModel from "../APIModel";

export default class UserApps extends ApiModel {
  constructor() {
    super("weather");
  }

  async get(url: string): Promise<any> {
    return await this.http
      .setResponseType("text")
      .ignoreErrorHandler()
      .get(url.toLowerCase(), {});
  }

  async getCity(city: string): Promise<any> {
    return await this.http
      .setResponseType("text")
      .ignoreErrorHandler()
      .get(`city_search/${city}`, {});
  }

  async getCurrentWeather(): Promise<any> {
    return await this.http
      .setResponseType("text")
      .ignoreErrorHandler()
      .get(`${this.resourceUrl}/current_place`, {});
  }
}
