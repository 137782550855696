import ApiModel from "../APIModel";

export default class GroupEx extends ApiModel {
  constructor() {
    super("groupEx");
  }

  async getGroups(): Promise<any> {
    return await this.http
      .setResponseType("text")
      .get(
        "https://gym-scheduling-app-backend.screencloudapp.com/api/groupex/schedule?locationId=3718&accountId=36",
        {}
      );
  }
}
