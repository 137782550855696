import store from "@/store";

const userPages: any = {
  admin: "/files"
};
const credentials = store.getters["authentication/credentials"];

export default [
  {
    path: `/`,
    redirect: credentials.user
      ? userPages[credentials.user.role]
      : "/auth/login"
  }
];
