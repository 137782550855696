const meta = {
  module: "account"
};

export default [
  {
    path: "/account",
    redirect: "/account/security"
  },
  {
    path: `/account`,
    name: "account",
    component: () =>
      import(
        /* webpackChunkName: "account" */ "@/modules/account/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/account/:action`,
    name: "account",
    component: () =>
      import(
        /* webpackChunkName: "profile" */ "@/modules/account/views/index.vue"
      ),
    meta: {
      ...meta
    }
  }
];
