const meta = {
  module: "auth",
  layout: "auth",
  withoutCredentials: true
};

export default [
  {
    path: `/auth/login`,
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/modules/auth/views/login.vue"),
    meta: {
      ...meta
    }
  },
  {
    path: "/auth/registration",
    name: "registration",
    component: () =>
      import(
        /* webpackChunkName: "registration" */ "@/modules/auth/views/registration.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: "/auth/email-confirmed",
    name: "email-confirmed",
    component: () =>
      import(
        /* webpackChunkName: "email-confirmed" */ "@/modules/auth/views/email-confirmed-page.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: "/auth/email-expired",
    name: "email-expired",
    component: () =>
      import(
        /* webpackChunkName: "email-confirmed" */ "@/modules/auth/views/email-expired-page.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: "/auth/reset-password-success",
    name: "email-expired",
    component: () =>
      import(
        /* webpackChunkName: "email-confirmed" */ "@/modules/auth/views/reset-password-page.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: "/auth/reset-password/:token",
    name: "email-expired",
    component: () =>
      import(
        /* webpackChunkName: "email-confirmed" */ "@/modules/auth/views/reset-password.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: "/auth/email-sent",
    name: "email-confirmed",
    component: () =>
      import(
        /* webpackChunkName: "email-confirmed" */ "@/modules/auth/views/email-sent-page.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/auth/verification`,
    name: "two-factor-verification",
    component: () =>
      import(
        /* webpackChunkName: "verification" */ "@/modules/auth/views/verification.vue"
      ),
    meta: {
      ...meta
    }
  }
];
