//@ts-ignore
import notificationAudio from "@/assets/sounds/notification.mp3";

export default {
  namespaced: true,

  state: {
    show: false,
    timeout: 4000,
    messages: []
  },

  mutations: {
    async show(state: any, payload: any) {
      let index = state.messages.length;

      if (index) {
        index = state.messages[index - 1].index + 1;
      }

      state.messages.push({ ...payload, index });

      if (!payload.hasOwnProperty("withoutClosing")) {
        setTimeout(() => {
          state.messages.splice(
            state.messages.findIndex((item: any) => item.index === index),
            1
          );
        }, state.timeout);
      }

      if (payload.hasOwnProperty("enableSound")) {
        const player = new Audio(notificationAudio);
        await player.play();
      }

      state.show = true;
    },
    hide(state: any, index: number) {
      state.messages.splice(index, 1);
    },
    hideAll(state: any) {
      state.show = false;
    }
  },

  actions: {
    /**
     * @param commit
     * @param {Object} payload - Alert object
     * @param {string} payload.text
     * @param {string} payload.color
     * @param {string} payload.icon
     * @param {boolean} payload.withoutClosing
     * @param {boolean} payload.enableSound
     * @param {Function} payload.onClick
     * @param {string} payload.title
     * @param {Array} payload.list
     * */
    showSuccess({ commit }: any, payload: any) {
      if (typeof payload === "string") {
        commit("show", { text: payload, type: "success" });
      } else {
        commit("show", { ...payload, type: "success" });
      }
    },
    showError({ commit }: any, payload: any) {
      if (typeof payload === "string") {
        commit("show", { text: payload, type: "error" });
      } else {
        commit("show", { ...payload, type: "error" });
      }
    },
    showWarning({ commit }: any, payload: any) {
      if (typeof payload === "string") {
        commit("show", { text: payload, type: "warning" });
      } else {
        commit("show", { ...payload, type: "warning" });
      }
    },
    showInfo({ commit }: any, payload: any) {
      if (typeof payload === "string") {
        commit("show", { text: payload, type: "info" });
      } else {
        commit("show", { ...payload, type: "info" });
      }
    },
    hide({ commit }: any, index: number) {
      commit("hide", index);
    },
    hideAll({ commit }: any) {
      commit("hide");
    }
  },

  getters: {
    show: (state: any) => state.show,
    messages: (state: any) => state.messages
  }
};
