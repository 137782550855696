var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          staticStyle: { "border-radius": "45px" },
          attrs: { id: "dialog", "max-width": "737px", persistent: "" },
          model: {
            value: _vm.showed,
            callback: function($$v) {
              _vm.showed = $$v
            },
            expression: "showed"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "hidden-xs-only",
              staticStyle: {
                "box-shadow": "0px 10px 25px #14121F1A",
                "border-radius": "45px"
              },
              attrs: { height: "280px", width: "737px" }
            },
            [
              _c("div", {
                staticClass:
                  "confirm-card__text d-flex align-center medium-font text-center",
                staticStyle: {
                  height: "145px",
                  background: "var(--v-light-base)",
                  padding: "0 123px"
                },
                domProps: { innerHTML: _vm._s(_vm.params.message) }
              }),
              _c(
                "div",
                {
                  staticClass: "d-flex align-center justify-center",
                  staticStyle: { height: "135px" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center justify-center" },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "primary--text cursor-pointer medium-font tt-font-family mr-15 font-weight-medium",
                          on: {
                            click: function($event) {
                              return _vm.cancel()
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.params.cancelText) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "#14121F",
                            depressed: "",
                            height: "55",
                            rounded: "",
                            width: "147"
                          },
                          on: {
                            click: function($event) {
                              return _vm.confirm()
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "medium-font white--text tt-font-family"
                            },
                            [_vm._v(" " + _vm._s(_vm.params.confirmText))]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-center hidden-sm-and-up" },
        [
          _c(
            "v-bottom-sheet",
            {
              attrs: { "hide-overlay": "" },
              model: {
                value: _vm.showed,
                callback: function($$v) {
                  _vm.showed = $$v
                },
                expression: "showed"
              }
            },
            [
              _c(
                "v-sheet",
                { staticClass: "text-center hidden-sm-and-up" },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-text", [_vm._v(_vm._s(_vm.params.message))]),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        { staticClass: "justify-space-around" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.confirm()
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.params.confirmText) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "" },
                              on: {
                                click: function($event) {
                                  return _vm.cancel()
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.params.cancelText) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }