const meta = {
  module: "preview",
  withoutCredentials: true
};

export default [
  {
    path: `/preview`,
    name: "preview",
    component: () =>
      import(
        /* webpackChunkName: "preview" */ "@/modules/preview/views/index.vue"
      ),
    meta: {
      ...meta
    }
  },
  {
    path: `/public-screen/:id`,
    name: "public-screen",
    component: () =>
      import(
        /* webpackChunkName: "public-screen" */ "@/modules/preview/views/public-screen.vue"
      ),
    meta: {
      ...meta
    }
  }
];
