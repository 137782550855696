import ApiModel from "../APIModel";

export default class SportScores extends ApiModel {
  constructor() {
    super("sport_scores");
  }

  async getDisciplines(): Promise<any> {
    return await this.http
      .setResponseType("text")
      .ignoreErrorHandler()
      .get(`${this.resourceUrl}/disciplines`, {});
  }

  async getData(value: any): Promise<any> {
    return await this.http
      .setResponseType("text")
      .ignoreErrorHandler()
      .get(`${this.resourceUrl}/${value}`, {});
  }
}
