import Http from "@/services/http";

export default class APIModel {
  protected readonly http = new Http();
  protected readonly resourceUrl: string;

  constructor(resourceUrl: string) {
    this.resourceUrl = resourceUrl;
  }

  async get(params = {}): Promise<any> {
    return await this.http
      
      .ignoreGlobalPreloader()
      .get(`${this.resourceUrl}`, params);
  }

  async show(id: number): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/${id}/show`, {});
  }

  async getList(params = {}): Promise<any> {
    return await this.http
      .ignoreErrorHandler(500)
      .get(`${this.resourceUrl}/list`, params);
  }

  async edit(id: number, payload: object): Promise<any> {
    return await this.http
      
      .patch(`${this.resourceUrl}/${id}`, payload);
  }

  async create(payload: object): Promise<any> {
    return await this.http
      
      .post(`${this.resourceUrl}`, payload);
  }

  async update(id: number, payload: object): Promise<any> {
    return await this.http
      
      .patch(`${this.resourceUrl}/${id}`, payload);
  }

  async delete(params: object): Promise<any> {
    return await this.http.delete(`${this.resourceUrl}`, params);
  }

  async download(url: string): Promise<any> {
    return await this.http.setResponseType("blob").get(url, {});
  }
}
