export default {
  namespaced: true,
  state: {
    appVersion: "" as string,
    credentials:
      (JSON.parse(
        localStorage.getItem("credentials") as string
      ) as CredentialsInterface) || {},
    hasAccessToken: !!localStorage.getItem("credentials") as boolean
  },

  mutations: {
    setAppVersion(state: any, version: string) {
      state.appVersion = version;
    },
    login(state: any, credentials: CredentialsInterface) {
      state.hasAccessToken = true;
      state.credentials = credentials;
    },
    logout(state: any) {
      state.credentials = {};
      state.hasAccessToken = false;
    }
  },

  actions: {
    login({ commit }: any, payload: CredentialsInterface) {
      commit("login", payload);
      localStorage.setItem("credentials", JSON.stringify(payload));
    },
    logout({ commit }: any) {
      commit("logout");
      localStorage.removeItem("credentials");
      localStorage.removeItem("lang");
      location.href = `${location.origin}/en/auth/login`;
    },
    removeCredentialsLocally({ commit }: any) {
      commit("logout");
    },
    checkAppVersion({ commit }: any) {
      const currentVersion = process.env.PACKAGE_VERSION;
      const localeVersion = localStorage.getItem("APP_VERSION");

      if (localeVersion && currentVersion !== localeVersion) {
        localStorage.clear();
        document.location.reload();
      } else {
        localStorage.setItem("APP_VERSION", currentVersion || "0");
        commit("setAppVersion", currentVersion);
      }
    }
  },

  getters: {
    hasAccessToken: (state: any): boolean => state.hasAccessToken,
    credentials: (state: any): CredentialsInterface => state.credentials,
    getAppVersion: (state: any): string => state.appVersion
  }
};
