export default {
  namespaced: true,
  state: {
    queryString: ""
  },

  mutations: {
    set(state: any, payload: string) {
      state.queryString = payload;
    }
  },

  actions: {
    set({ commit }: any, payload: string) {
      commit("set", payload);
    }
  },

  getters: {
    get: (state: any): string => state.queryString
  }
};
